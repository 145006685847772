import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import SubHero from '../../components/elements/sub-hero/sub-hero';
import SubIntro from '../../components/elements/sub-intro/sub-intro';
import BookCta from '../../components/elements/book-cta/book-cta';

const ServicePage = ({ data }) => {

  let [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout pageType='beauty'>
      <SEO
        keywords={[`Facials Ely`, `Relaxing Facials Ely`, `Pure Facials Ely`, `Spa Facials Ely`]}
        title="Pure Facials"
      />
      <SubHero 
        marginBottom
        loaded={loaded}
        bgImage={data.hero.childImageSharp.fluid}
        data={{
          title: 'Pure Facials',
          subTitle: '*Time allocated includes consultation',
          twoCol: [
            <div className="mb-10 md:mb-0" key={1}>
              <div className="mb-5">
                <h4 className="text-xl mb-2">PURE DERMAPLANING <b>£71</b></h4>
                <p className="font-light">This treatment is purely dermaplaning. It works  on all skin types to reduce any dullness, fine lines, and acne scarring. This treatment gets  rid of fine hairs and dead skin cells on the surface of the skin, helping the luxurious finishing products to absorb even deeper into the skin.</p>
              </div>
              <div className="mb-5">
                <h4 className="text-xl mb-2 uppercase">Pure TimeExpert Rides Facial <b>£71</b></h4>
                <p className="font-light">Using pro-collagestine fill technology, we use ingredients that work with the dermis to increase the skins natural production of collagen and elastin. Overall, helping to plump out lines and wrinkles from beneath.</p>
              </div>
              <div className="mb-5">
                <h4 className="text-xl mb-2 uppercase">Acne Cleanse <b>£50.50</b></h4>
                <p className="font-light">For oily or acne prone skin to help with congestion and oil production. Our Dermaquest skin care range leaves the skin cleansed and hydrated.</p>
              </div>
              <div>
                <h4 className="text-xl mb-2 uppercase"> Bright Eyes Express <b>£36</b></h4>
                <p className="font-light">A combination of hydrating and protective ingredients to preserve the skin and plump out lines and wrinkles. A fantastic freshener which rolls back the years in one treatment.</p>
              </div>
            </div>,
            <div key={2}>
              <div className="mb-5">
                <h4 className="text-xl mb-2 uppercase">Pure TimeExpert SRNS Facial <b>£76</b></h4>
                <p className="font-light">A powerful all-round anti-ageing treatment that provides wrinkle reduction, revitalisation, and hydration. Using highly concentrated, active ingredients in our products means we can reactivate the skins younger proteins.</p>
              </div>
              <div className="mb-5">
                <h4 className="text-xl mb-2 uppercase">Pure Hydraluronic<b>£71</b></h4>
                <p className="font-light">The most essential treatment for anti-aging and hydration! Using products to smooth the skin within and restores plumpness with its intense, long-lasting hydrating products.</p>
              </div>
              <div className="mb-5">
                <h4 className="text-xl mb-2 uppercase">Pure Vitamin C + A.G.E Facial <b>£71</b></h4>
                <p className="font-light">Combining ingredients to repair and prevent the negative effects of degenerative collagen and elastin fibres.
                The star ingredient used in the treatment is the Ume Extract from a Japanese plum, which helps eliminate the dull, pallid tones that our skin has.</p>
              </div>
              <div className="mb-5">
                <h4 className="text-xl mb-2 uppercase">Glycocure Express <b>£41</b></h4>
                <p className="font-light">Glycolic acid renews the cells at the deepest level. This potent elixir improves the appearance of wrinkles, pigmentation and congested skin. A powerful treatment which delivers results. Not suitable for sensitive skin.</p>
              </div>
              <div>
                <h4 className="text-xl mb-2 uppercase">Pure Laser Carbon Facial <b>£99</b></h4>
                <p className="font-light">Also known as our Hollywood facial! This carbon laser facial reduces the appearance of fine lines, wrinkles and overall appearance of the skin. It’s a great anti aging treatment for the face, neck, back and hands.</p>
              </div>
            </div>
          ]
        }}
      />
      <SubIntro 
        text={<p>Pamper and cleanse your skin with one of our luxury facials. From a cleansing treatment to soothe and exfoliate your face to dermaplaning reducing dullness, fine lines, and acne scarring, we have a facial treatment for everyone. </p>}
      />
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd#/service-category?id=bei0g7wMTMAyI6PfyBpJpg" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

ServicePage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    hero: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ServiceComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ServicePage props data={data} {...props} />
    )}
  />
)

ServiceComponent.displayName = "ServiceComponent"

export default ServiceComponent

